import React from 'react';
import { graphql, useStaticQuery, Link } from "gatsby"
import SEO from "../components/seo";
import Header from "../components/es/header";
import Footer from "./../components/footer";
import Img from "gatsby-image";

//Icon
import { BiArrowBack } from "react-icons/bi";

//Styles
import "./../styles/imms.scss";


const Imms = () => {

    const images = useStaticQuery(graphql`
        query {
            allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "siroc"}}, sort: {fields: name}){
                edges{
                    node{
                        name
                        childImageSharp{
                            fluid(quality: 90){
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    `)

    //Data
    let data = {
        "es": {
            services: [
                {
                    "title": "Registro, control y seguimiento de obras de construccion.",
                    'color': "#f9f6f0",
                    "content":
                        <ol>
                            <li>Generación de registrol patronales por obra.</li>
                            <li>Movimientos afilatorios de sus trabajadores (altas, bajas, modificaciones salariales).</li>
                            <li>Registro de obra mediante SIROC (Propietario, Contratista, Subcontratista e Intermediario).</li>
                            <li>Seguimiento de obras.</li>
                            <ul>
                                <li>Incidencias.</li>
                                <li>Reporte bimestral.</li>
                                <li>Actualización (modificación de datos).</li>
                                <li>Suspensión.</li>
                                <li>Reanudación.</li>
                                <li>Cancelación de obras.</li>
                                <li>Terminación de obras.</li>
                            </ul>
                            <li>Pre-altas patronales, citas y acompañamiento presencial (en subdelegación).</li>
                            <li>Servicios digitales.</li>
                            <li>Alta de representantes legales.</li>
                            <li>Generación de altas patronales.</li>
                            <li>Alta de empresas representadas.</li>
                        </ol>
                },
                {
                    "title": "Control de subcontratistas.",
                    "color": "white",
                    "content":
                        <ol>
                            <li>Revisión y control de subcontratos.</li>
                            <li>Altas patronales.</li>
                            <li>Registros patronales por obra.</li>
                            <li>Control de pagos y liquidaciones.</li>
                            <li>Control de registros de obra mediante SIROC o SATIC.</li>
                            <li>Seguimiento de subcontratación (incidencias).</li>
                            <li>Cancelación de subcontratación.</li>
                            <li>Prealtas patronales, citas y acompañamiento presencial (en subdelegación).</li>
                            <li>Servicios digitales.</li>
                            <ul>
                                <li>Alta de representantes legales.</li>
                                <li>Generación de altas patronales.</li>
                                <li>Alta de empresas representadas.</li>
                            </ul>
                        </ol>
                },
                {
                    "title": "Planeación fiscal de obras de construcción.",
                    "color": "#f9f6f0",
                    "content":
                        <ol>
                            <li>Planeación fiscal.</li>
                            <li>Terminación de obra.</li>
                            <li>Liberación de obra mediante autocorreción patronal.</li>
                        </ol>
                },
                {
                    "title": "Defensa fiscal.",
                    "color": "white",
                    "content":
                        <ol>
                            <li>Presentación de Recursos de Revocación (ante IMMS).</li>
                            <li>Defensa contra organismos fiscalizadores (IMMS, SAT) en materia del Procedimiento Administrativo de Ejecución (PAE), facultades de comprobación, notificaciones ilegales, ilegalidad en la determinación del crédito (ante Juicio).</li>
                            <li>Representación jurídica.</li>
                            <li>Amparo contra órdenes de revisión violatorias de derechos fundamentales (indirecto).</li>
                            <li>Amparo contra leyes fiscales, violatorias de derechos fundamentales (indirecto).</li>
                            <li>Amparo contra sentencias definitivas (directo).</li>
                        </ol>
                },
                {
                    "title": "Sistema único de autodeterminación (SUA).",
                    "color": "#f9f6f0",
                    "content":
                        <ol>
                            <li>Manejo de IMMS Desde Su Empresa (IDSE).</li>
                            <li>Actualización constante de SUA.</li>
                            <li>Afiliación de trabajadores sistematizado.</li>
                            <ul>
                                <li>Altas.</li>
                                <li>Bajas.</li>
                                <li>Modificaciones Salariales.</li>
                            </ul>
                            <li>Determinación de prima de RT y presentación anual de siniestralidad.</li>
                            <li>Confronta (evitar evidencias, SIVEPAS).</li>
                            <li>Atención de requerimientos por pagos improcedentes.</li>
                        </ol>
                },
                {
                    "title": "Dictamen para efectos del Seguro Social (en SIDEIMMS).",
                    color: "white",
                    "subtitle": "(SIDEIMMS: Sistema de Dictamen Electrónico del Instituto Mexicano del Seguro Social).",
                    "content":
                        <ol>
                            <li>Diagnóstico de la empresa.</li>
                            <li>Presentación de aviso de Dictamen.</li>
                            <li>Elaboración de Dictamen.</li>
                            <li>Auditoría a dictamen antes de la presentación de resultado del dictamen.</li>
                            <li>Presentación de Dictamen.</li>
                            <li>Presentación de Movimientos Afiliatorios.</li>
                            <li>Pago de diferencias.</li>
                            <li><b>Defensa fiscal.</b></li>
                            <li>Atención a requerimientos.</li>
                            <li>Conciliación con autoridades del IMMS.</li>
                        </ol>
                }
            ]
        },
    }

    let lang = "es";

    return(
        <section className="imms">
            <SEO
                title="Procesos IMMS SIROC"
                description="En Consultoría Integral Estructurada ofrecemos consultoría, acompañamiento y realización de los procesos SIROC. Nuestro plan de trabajo contempla todas las áreas del proceso y su negocio estará en buenas manos con nuestros expertos. "
            />
            <Header checked={""} />


                <article>
                    <div className="title">
                        <Link to="/" className="link" > <BiArrowBack size="18" className="icon" /> Inicio </Link>
                        <h1>Procesos IMMS SIROC</h1>
                    </div>

                    <main>
                        <div className="overlay"></div>
                    {
                        data[lang].services.map((el, index) => 
                            <div className="panel" style={{ backgroundColor: [index % 2 === 0 ? "white" : "#f9f6f0"]}}>
                                <div className="data">
                                    <h2>{el.title}</h2>
                                    {
                                        el.subtitle ? <span>{el.subtitle}</span> : null
                                    }
                                    {
                                        el.content
                                    }
                                </div>
                                <Img fluid={images.allFile.edges[index].node.childImageSharp.fluid} className="img"/>
                            </div>
                        )
                    }
                    </main>

                </article>
        
        <Footer lang="es" />

        </section>
    );
};

export default Imms;

{/**
<Controller globalSceneOptions={{ triggerHook: 'onLeave' }}  >

                        {
                            images.allFile.edges.map( (edge, index) =>
                                <Scene pin>
                                    <div className="panel" style={{backgroundColor:[data[index].color]}}>
                                        <div className="data">
                                            <h2>{data[index].title}</h2>
                                            {data[index].subtitle ? <span>{data[index].subtitle}</span> : null}
                                            {
                                                data[index].content
                                            }
                                        </div>

                                        <Img fluid={edge.node.childImageSharp.fluid} className="img" />
                                    </div>
                                </Scene>
                            )
                        }

                    </Controller>

*/}